import React from 'react'
import styled from 'styled-components'

export default function Careers() {
  const ourProjects = [
    {
      id: 1,
      title: 'Senior Architect',
      color: '#DBEFFF',
      image:
        'https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex/Logo.svg',
      description: ' With 5-7 years experience',
      location: 'Malabar Office',
      time: '6 Hr ago',
    },
    {
      id: 2,
      title: 'Junior Architect',
      color: '#EFEBF6',
      image:
        'https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex/Logo.svg',
      description: ' With 1 - 4 years experience',
      location: 'Malabar Office',
      time: '6 Hr ago',
    },
    // {
    //   id: 3,
    //   title: 'Construciton Engineer',
    //   color: '#F9F5E8',
    //   image:
    //     'https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex/Logo.svg',
    //   description:
    //     'Lorem ipsum dolor sit ame habitas consectetur. Praesent ipsum agitay dignissim habitasse amet ',
    //   location: 'Malabar Office',
    //   time: '6 Hr ago',
    // },

    // {
    //   id: 4,
    //   title: 'Senior Project Manager',
    //   color: '#E2F2ED',
    //   image:
    //     'https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex/Logo.svg',
    //   description:
    //     'Lorem ipsum dolor sit ame habitas consectetur. Praesent ipsum agitay dignissim habitasse amet ',
    //   location: 'Singapore Office',
    //   time: '6 Hr ago',
    // },

    // {
    //   id: 5,
    //   title: 'Senior Project Manager',
    //   color: '#DBEFFF',
    //   image:
    //     'https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex/Logo.svg',
    //   description:
    //     'Lorem ipsum dolor sit ame habitas consectetur. Praesent ipsum agitay dignissim habitasse amet ',
    //   location: 'Singapore Office',
    //   time: '6 Hr ago',
    // },
    // {
    //   id: 6,
    //   title: 'Jr Construction Engineer',
    //   color: '#EFEBF6',
    //   image:
    //     'https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex/Logo.svg',
    //   description:
    //     'Lorem ipsum dolor sit ame habitas consectetur. Praesent ipsum agitay dignissim habitasse amet ',
    //   location: 'Malabar Office',
    //   time: '6 Hr ago',
    // },
  ]
  return (
    <Container id="careers">
      <Cover className="wrapper">
        <p className="blue">Careers</p>
        <Head>Join with Us</Head>
        <Description>
          We're an innovative, fast-growing company with all the opportunity for
          impact and career growth. We're building an outstanding team and the
          positions we’re hiring for at the moment are:
        </Description>
        <Ul>
          {ourProjects.map((item) => (
            <Li key={item.id}>
              <ImageContainer>
                <ProjectImage>
                  <img src={item.image} alt="Project" />
                </ProjectImage>
              </ImageContainer>

              <Details>
                <Title>{item.title}</Title>
                <SubDescription>{item.description}</SubDescription>
              </Details>

              <ButtonContainer>
                <Button href="mailto:hr@ifuex.in">Apply Now</Button>
              </ButtonContainer>
            </Li>
          ))}
        </Ul>
        {ourProjects.length > 4 ? (
          <ArrowContainer>
            <RightArrowDiv className="right">
              <RightArrow
                src="https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex%2Fleft.svg"
                alt="Icon"
              />
            </RightArrowDiv>
            <LeftArrowDiv>
              <LeftArrow
                src="https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex%2Fright.svg"
                alt="Icon"
              />
            </LeftArrowDiv>
          </ArrowContainer>
        ) : (
          ''
        )}
      </Cover>
    </Container>
  )
}
const Container = styled.section`
  padding: 100px 0;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-size: cover;
  @media all and (max-width: 1280px) {
    padding: 80px 0;
  }
  @media all and (max-width: 980px) {
    padding: 70px 0;
  }
  @media all and (max-width: 640px) {
    padding: 60px 0;
  }
  @media all and (max-width: 480px) {
    padding: 50px 0;
  }
`
const Cover = styled.section`
  &.wrapper {
    width: 75%;
    margin: 0 auto;
    @media (max-width: 1200px) {
      width: 80%;
    }
    @media all and (max-width: 980px) {
      flex-wrap: wrap;
    }
    @media all and (max-width: 640px) {
      width: 85%;
    }
    @media (max-width: 480px) {
      width: 90%;
    }
  }
  p.blue {
    background-image: linear-gradient(45deg, #20a0f3, #336687);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    font-family: 'gordita_medium';
    text-align: center;
    margin-bottom: 15px;
  }
`
const Head = styled.h3`
  color: #212121;
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
  font-family: 'gordita_bold';

  @media all and (max-width: 1080px) {
    font-size: 24px;
  }
  @media all and (max-width: 980px) {
    text-align: center;
  }

  b {
    color: #0bbe61;
    font-family: gordita_bold;
  }
`
const Description = styled.p`
  font-size: 16px;
  text-align: center;
  width: 70%;
  margin: 0 auto 80px auto;
  color: #747474;
  @media all and (max-width: 1080px) {
    font-size: 14px;
    width: 100%;
  }
  @media all and (max-width: 768px) {
    margin: 0 auto 50px auto;
  }
`
const Ul = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  /* margin-bottom: 50px; */
  width: 60%;
  @media all and (max-width: 1200px) {
    width: 80%;
  }
  @media all and (max-width: 980px) {
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
  @media all and (max-width: 768px) {
    grid-gap: 30px;
    grid-template-columns: 1fr;
  }
  @media all and (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`
const Li = styled.div`
  padding: 25px;
  position: relative;
  background: #fff;
  border-radius: 10px;
  border: 1.5px solid #fff;
  transition: all 0.5s ease;
  /* background-color: red; */
  box-shadow: 0px 0px 31px rgb(0 0 0 / 6%);
  width: auto;
  &.active {
    border: 1.5px solid #32c997;
    transition: all 0.5s ease;
  }
  &:nth-last-child(-n + 3) {
    margin-bottom: 0;
  }

  @media all and (max-width: 768px) {
    height: auto;
    width: 60%;
  }

  @media all and (max-width: 480px) {
    width: 80%;
  }
`
const ProjectImage = styled.div`
  width: 100px;

  img {
    width: 100%;
    display: block;
  }

  & p {
    font-family: 'gordita_bold';
    font-size: 22px;
    position: absolute;
    bottom: -8px;
    right: -8px;
  }

  @media all and (max-width: 768px) {
    width: 65px;
  }
  @media all and (max-width: 480px) {
    width: 55px;
  }
`
const Details = styled.div`
  font-size: 23px;
  margin-bottom: 30px;
  min-height: 100px;

  @media all and (max-width: 1080px) {
    margin-bottom: 20px;
  }
  @media all and (max-width: 980px) {
    /* min-height: 95px; */
    margin-bottom: 20px;
  }
  @media all and (max-width: 768px) {
    min-height: auto;
  }
`
const Title = styled.h5`
  color: #0a0a0a;
  margin-bottom: 20px;
  font-size: 18px;
  font-family: 'gordita_medium';
  @media all and (max-width: 980px) {
    font-size: 16px;
  }
`
const SubDescription = styled.p`
  color: #747474;
  font-size: 16px;
  @media all and (max-width: 980px) {
    font-size: 14px;
  }
  @media all and (max-width: 640px) {
    color: #747474;
  }
`
const ImageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
  min-height: 70px;
  @media all and (max-width: 768px) {
    margin-bottom: 20px;
  }
`
const ButtonContainer = styled.div`
  width: 50%;
  display: inline-block;
`
const Button = styled.a`
  background: #fff;
  width: 125px;
  text-align: center;
  color: #2e8fce;
  padding: 10px;
  font-size: 14px;
  border-radius: 7px;
  font-family: 'gordita_medium';
  cursor: pointer;
  color: #fff;
  display: inline-block;
  transition: all 0.7s ease;
  background: linear-gradient(90deg, #20a0f3 -12.92%, #006baf 131.76%);
  &:hover {
    transition: all 0.7s ease;
    background: linear-gradient(90deg, #006baf -12.92%, #20a0f3 131.76%);
  }
  @media all and (max-width: 1400px) {
    padding: 10px;
  }
  @media all and (max-width: 1080px) {
    padding: 10px;
  }
  @media all and (max-width: 980px) {
    margin: 0 auto;
  }
  @media all and (max-width: 768px) {
    margin: 0 auto;
  }
  @media all and (max-width: 640px) {
  }
  @media all and (max-width: 480px) {
  }
`
const ArrowContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 30px;
`
const LeftArrowDiv = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 25px;
  background: #fff;
  padding: 10px;
  display: flex;
  border: 1px solid #f1f1f1;
  cursor: pointer;
  transition: all 0.5s ease;

  &:hover {
    transition: all 0.5s ease;
    background: #f6f1f1;
  }
`
const RightArrowDiv = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 25px;
  background: #fff;
  padding: 10px;
  display: flex;
  border: 1px solid #f1f1f1;
  cursor: pointer;
  margin-right: 15px;
  transition: all 0.5s ease;

  &:hover {
    transition: all 0.5s ease;
    background: #f6f1f1;
  }
`
const LeftArrow = styled.img`
  display: block;
  width: 100%;
`
const RightArrow = styled.img`
  display: block;
  width: 100%;
`
