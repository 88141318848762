import React, { useState } from 'react'
import styled from 'styled-components'

export default function Service() {
  const [isitem, Setitem] = useState(false)
  const ourProjects = [
    {
      id: 1,
      title: 'Construction Division',
      color: '#DBEFFF',
      image:
        'https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex/construction.svg',
      description:
        'Highly skilled and quality workforce which includes experienced engineers, supervisors and construction teams along with the advanced construction tools & equipments, formworks and scaffold.',
      class: 'construction',
    },
    {
      id: 2,
      title: 'EPC Division',
      color: '#EFEBF6',
      image:
        'https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex/epc.svg',
      description:
        'A single point, responsible for all the design, engineering, procurement, construction, commissioning, testing & handover activities on a Lump Sum Turnkey basis.',
      class: 'EPC',
    },
    {
      id: 3,
      title: 'Real-Estate Development Division',
      color: '#F9F5E8',
      image:
        'https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex/mep.svg',
      description:
        'Fully prepared and slated to develop prestigious eco-friendly  residential as well commercial projects at various demanding locations across the state. Can turn your static property into a  dynamic value-added asset to pay-off in the shortest possible time. Our team would love to talk to you if you own   land in premium locations.',
      class: 'real',
    },

    {
      id: 4,
      title: 'Infrastructure Projects Division',
      color: '#E2F2ED',
      image:
        'https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex/solar.svg',
      description:
        'To undertake government infrastructure projects on a fast-track mode with innovative construction 4.0 technologies. Soon getting positioned to deploy even 3D Printers, Construction Robots, Drones etc. for a rapid delivery and cost reduction of new builds of complex shapes and designs.',
      class: 'infra',
    },
  ]
  return (
    <Container id="service">
      <Cover className="wrapper">
        <p className="blue">What We Provide</p>
        <Head>Our Services</Head>
        <Description>
          We provide services all over the world and we are a quality
          engineering firm. We serve quality services to our clients in
          different engineering sections. Our team of technologists, architects,
          builders and engineers work cohesively to understand the intricate
          nature of each project and deliver clear results for our clients. To
          lead the built environment in creating a better future today.
        </Description>
        <Ul>
          {ourProjects.map((item) => (
            <Li
              className={item.id === isitem ? 'active' : ''}
              onMouseEnter={() => {
                Setitem(item.id)
              }}
              onMouseLeave={() => {
                Setitem('')
              }}
              key={item.id}
            >
              <ImageContainer>
                <ProjectImage style={{ backgroundColor: item.color }}>
                  <img src={item.image} alt="Project" />
                </ProjectImage>
              </ImageContainer>

              <Details>
                <Title className={item.id === isitem ? 'active' : ''}>
                  {item.title}
                </Title>
                <SubDescription className={item.id === isitem ? 'active' : ''}>
                  {item.description}
                </SubDescription>
              </Details>
            </Li>
          ))}
        </Ul>
      </Cover>
    </Container>
  )
}
const Container = styled.section`
  padding: 100px 0;
  background-color: #fbfbfb;
  background-repeat: no-repeat;
  background-size: cover;
  @media all and (max-width: 1280px) {
    padding: 80px 0;
  }
  @media all and (max-width: 980px) {
    padding: 70px 0;
  }
  @media all and (max-width: 640px) {
    padding: 60px 0;
  }
  @media all and (max-width: 480px) {
    padding: 50px 0;
  }
`
const Cover = styled.section`
  &.wrapper {
    width: 75%;
    margin: 0 auto;
    @media (max-width: 1200px) {
      width: 80%;
    }
    @media all and (max-width: 980px) {
      flex-wrap: wrap;
    }
    @media all and (max-width: 640px) {
      width: 85%;
    }
    @media (max-width: 480px) {
      width: 90%;
    }
  }
  p.blue {
    background-image: linear-gradient(45deg, #20a0f3, #336687);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    font-family: 'gordita_medium';
    text-align: center;
    margin-bottom: 15px;
  }
`
const Head = styled.h3`
  color: #212121;
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
  font-family: 'gordita_bold';

  @media all and (max-width: 1080px) {
    font-size: 24px;
  }
  @media all and (max-width: 980px) {
    text-align: center;
  }

  b {
    color: #0bbe61;
    font-family: gordita_bold;
  }
`
const Description = styled.p`
  font-size: 16px;
  text-align: center;
  width: 70%;
  margin: 0 auto 80px auto;
  color: #747474;
  @media all and (max-width: 1080px) {
    font-size: 14px;
    width: 100%;
  }
  @media all and (max-width: 768px) {
    margin: 0 auto 50px auto;
  }
`
const Ul = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  width: 80%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  @media all and (max-width: 1200px) {
    width: 100%;
  }
  @media all and (max-width: 768px) {
    grid-gap: 30px;
    grid-template-columns: 1fr;
  }
  @media all and (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`
const Li = styled.div`
  /* width: 80%; */
  padding: 25px;
  height: -webkit-fill-available;
  position: relative;
  background: #fff;
  border-radius: 10px;
  border: 1.5px solid #fff;
  transition: all 0.5s ease;
  &.active {
    border: 1.5px solid #32c997;
    transition: all 0.5s ease;
  }
  &:nth-last-child(-n + 3) {
    margin-bottom: 0;
  }

  @media all and (max-width: 1400px) {
    height: -webkit-fill-available;
  }
  @media all and (max-width: 1300px) {
    height: -webkit-fill-available;
  }

  @media all and (max-width: 1200px) {
    height: -webkit-fill-available;
  }
  @media all and (max-width: 1080px) {
    height: -webkit-fill-available;
  }
  @media all and (max-width: 980px) {
    /* height: 480px; */
    height: -webkit-fill-available;
  }

  @media all and (max-width: 768px) {
    width: 100%;
    /* height: 320px; */
    height: auto;
  }
`
const ProjectImage = styled.div`
  margin-right: 20px;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  padding: 15px;
  display: flex;
  justify-content: center;
  background: linear-gradient(90deg, #20a0f3 -12.92%, #006baf 131.76%);
  margin: 0 auto;

  & p {
    font-family: 'gordita_bold';
    font-size: 22px;
    position: absolute;
    bottom: -8px;
    right: -8px;
  }

  @media all and (max-width: 768px) {
    width: 65px;
    height: 65px;
  }
  @media all and (max-width: 480px) {
    width: 55px;
    height: 55px;
  }
`
const Details = styled.div`
  font-size: 23px;
`
const Title = styled.h5`
  color: #0a0a0a;
  margin-bottom: 10px;
  font-size: 18px;
  font-family: 'gordita_medium';
  @media all and (max-width: 980px) {
    font-size: 16px;
  }
`
const SubDescription = styled.p`
  color: #0a0a0a;
  font-size: 14px;
  @media all and (max-width: 980px) {
    font-size: 14px;
  }
  @media all and (max-width: 768px) {
    color: #747474;
  }
`
const ImageContainer = styled.div`
  width: 85px;
  height: 85px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 5px;
  border: 1px solid #006baf;
  @media all and (max-width: 768px) {
    width: 75px;
    height: 75px;
    padding: 0px;
  }
  @media all and (max-width: 480px) {
    width: 65px;
    height: 65px;
  }
`
