import React from 'react'
import styled from 'styled-components'

export default function About() {
  return (
    <>
      <Container id="about">
        <section className="wrapper">
          <Left>
            <img
              src="https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex/about-spot.png"
              alt="Startup School"
            />
          </Left>
          <Right>
            <p className="blue">About Us</p>
            <Title>Creative Architecture & Construction</Title>
            <Description>
              iFuex is a full-service Contractor for Civil, Mechanical and
              Electrical construction projects. Headquartered in Trivandrum,
              capital city of Kerala and is being promoted by a group of
              engineering & construction industry veterans. We are equipped to
              undertake multi-disciplinary design, detail engineering,
              procurement, construction and project management services.
            </Description>
            <Ul>
              <Li>
                <TickGreen>
                  <img
                    src="https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex/Checkbox.svg"
                    alt="Tick"
                  />
                </TickGreen>
                <p>Custom Design</p>
              </Li>
              <Li>
                <TickGreen>
                  <img
                    src="https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex/Checkbox.svg"
                    alt="Tick"
                  />
                </TickGreen>
                <p>Premium Quality</p>
              </Li>
              <Li>
                <TickGreen>
                  <img
                    src="https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex/Checkbox.svg"
                    alt="Tick"
                  />
                </TickGreen>
                <p>Daily Consultant</p>
              </Li>
              <Li>
                <TickGreen>
                  <img
                    src="https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex/Checkbox.svg"
                    alt="Tick"
                  />
                </TickGreen>
                <p>Friendly Ecosystem</p>
              </Li>
            </Ul>
          </Right>
        </section>
      </Container>
    </>
  )
}

const Container = styled.section`
  padding: 100px 0;
  background: #fbfbfb;
  & .wrapper {
    width: 75%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1200px) {
      width: 80%;
      margin: 0 auto;
    }
    @media all and (max-width: 980px) {
      flex-wrap: wrap;
    }
    @media all and (max-width: 640px) {
      width: 85%;
    }
    @media (max-width: 480px) {
      width: 90%;
    }
  }
  @media all and (max-width: 1280px) {
    padding: 80px 0;
  }
  @media all and (max-width: 980px) {
    padding: 70px 0;
  }
  @media all and (max-width: 640px) {
    padding: 60px 0;
  }
  @media all and (max-width: 480px) {
    padding: 50px 0;
  }
`
const Left = styled.div`
  width: 55%;

  @media all and (max-width: 1080px) {
    width: 65%;
  }
  @media all and (max-width: 980px) {
    width: 80%;
    margin: 0 auto 40px auto;
  }
  @media all and (max-width: 480px) {
    width: 100%;
  }
  img {
    width: 100%;
    display: block;
  }
`
const Right = styled.div`
  width: 40%;

  @media all and (max-width: 1080px) {
    width: 55%;
  }
  @media all and (max-width: 980px) {
    width: 100%;
    margin-bottom: 20px;
  }
  p.blue {
    background-image: linear-gradient(45deg, #20a0f3, #336687);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    font-family: 'gordita_medium';
    margin-bottom: 15px;
    @media all and (max-width: 980px) {
      text-align: center;
    }
  }
`
const Title = styled.h1`
  font-size: 26px;
  color: #212121;
  text-align: left;
  margin-bottom: 30px;
  font-family: 'gordita_bold';

  @media all and (max-width: 1080px) {
    font-size: 24px;
  }
  @media all and (max-width: 980px) {
    text-align: center;
  }
  @media all and (max-width: 640px) {
    font-size: 22px;
  }
  @media all and (max-width: 480px) {
    font-size: 20px;
  }
`
const Description = styled.p`
  font-size: 14px;
  text-align: left;
  margin-bottom: 30px;
  color: #7e7e7e;
  @media all and (max-width: 1080px) {
    font-size: 14px;
  }
  @media all and (max-width: 980px) {
    text-align: center;
  }
`
const Ul = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px 0;
  @media all and (max-width: 1080px) {
    justify-items: center;
  }
  @media all and (max-width: 980px) {
    width: 70%;
    margin: 0 auto;
  }
  @media all and (max-width: 768px) {
    width: 80%;
  }
  @media all and (max-width: 480px) {
    width: 90%;
  }
  @media all and (max-width: 360px) {
    width: 100%;
  }
`
const Li = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;

  @media all and (max-width: 980px) {
    align-items: center;
  }

  & p {
    font-size: 19px;
    color: #212121;
    font-family: 'gordita_medium';
    @media all and (max-width: 1280px) {
      font-size: 16px;
    }
    @media all and (max-width: 1080px) {
      font-size: 14px;
    }
    @media all and (max-width: 361px) {
      font-size: 13px;
    }
  }
  @media all and (max-width: 1080px) {
    font-size: 16px;
  }

  @media all and (max-width: 980px) {
    font-size: 18px;
    text-align: center;
  }
  @media all and (max-width: 768px) {
    font-size: 16px;
  }
  @media all and (max-width: 480px) {
    font-size: 14px;
    text-align: left;
  }

  &:last-child {
    margin-bottom: 0;
  }
`
const TickGreen = styled.div`
  width: 53px;
  padding: 15px;
  border-radius: 11px;
  box-shadow: 0 9px 61px rgb(0 0 0 / 21%);
  margin-bottom: 30px;
  img {
    width: 100%;
    display: block;
  }
  @media all and (max-width: 1080px) {
    width: 40px;
    padding: 10px;
  }
`
