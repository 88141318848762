import React from 'react'
import { Link } from 'react-scroll'
import styled from 'styled-components'

export default function WorkWithUs() {
  return (
    <Container id="work">
      <Cover className="wrapper">
        <Box>
          <LeftContainer>
            <Heading>Our Quality Assurance</Heading>
            <Description>
              We procure the raw material from quality driven organization that
              is checked at our quality inspection unit as to provide unique
              quality service to our esteemed clients. We offer these services
              after complete planning and designing of the project.
            </Description>
            <ButtonContainer>
              <Button to="contact" spy={true}>
                Contact Us
              </Button>
            </ButtonContainer>
          </LeftContainer>
          <RightContainer>
            <BgImageContainer>
              <BGImg
                src="https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex/Group.svg"
                alt="image"
              />
            </BgImageContainer>
          </RightContainer>
        </Box>
      </Cover>
    </Container>
  )
}
const Container = styled.section`
  padding: 100px 0;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  @media all and (max-width: 1280px) {
    padding: 80px 0;
  }
  @media all and (max-width: 980px) {
    padding: 70px 0;
  }
  @media all and (max-width: 640px) {
    padding: 60px 0;
  }
  @media all and (max-width: 480px) {
    padding: 50px 0;
  }
`
const Cover = styled.section`
  &.wrapper {
    width: 75%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1200px) {
      width: 80%;
    }
    @media all and (max-width: 980px) {
      flex-wrap: wrap;
    }
    @media all and (max-width: 640px) {
      width: 85%;
    }
    @media (max-width: 480px) {
      width: 90%;
    }
  }
  p.blue {
    color: #006baf;
    font-weight: 500;
    text-align: center;
    margin: 0 0 20px 0;
  }
`
const Box = styled.div`
  background: #31375b;
  display: flex;
  align-items: center;
  padding: 60px 0 60px 60px;
  border-radius: 15px;
  overflow: hidden;
  @media all and (max-width: 980px) {
    padding: 50px;
  }
  @media all and (max-width: 768px) {
    padding: 40px;
  }
  @media all and (max-width: 480px) {
    padding: 30px;
  }
`

const LeftContainer = styled.div`
  width: 60%;
  @media all and (max-width: 1080px) {
    width: 80%;
  }
  @media all and (max-width: 980px) {
    width: 100%;
  }
`
const Heading = styled.h1`
  font-family: 'gordita_medium';
  color: #fff;
  font-size: 26px;
  margin-bottom: 30px;
  @media all and (max-width: 980px) {
    font-size: 24px;
  }
  @media all and (max-width: 640px) {
    font-size: 22px;
  }
`
const Description = styled.p`
  color: #eeeeee;
  width: 100%;
  margin-bottom: 30px;
  @media all and (max-width: 1080px) {
    font-size: 14px;
  }
`
const ButtonContainer = styled.div`
  width: 50%;
  display: inline-block;
  @media all and (max-width: 360px) {
    width: 70%;
  }
  @media all and (max-width: 320px) {
    width: 80%;
  }
`
const Button = styled(Link)`
  background: #fff;
  width: 130px;
  text-align: center;
  color: #31375b;
  padding: 15px 20px;
  border-radius: 7px;
  font-size: 14px;
  font-family: 'gordita_medium';
  cursor: pointer;

  @media all and (max-width: 768px) {
    padding: 12px 20px;
  }
  @media all and (max-width: 640px) {
  }
  @media all and (max-width: 480px) {
    font-size: 13px;
  }
`
const RightContainer = styled.div`
  position: relative;
  width: 60%;
  @media all and (max-width: 1200px) {
    width: 85%;
  }
  @media all and (max-width: 980px) {
    display: none;
  }
`
const BgImageContainer = styled.div`
  transform: translateX(85px);
  @media all and (max-width: 1400px) {
    transform: translateX(65px);
  }
  @media all and (max-width: 1300px) {
    transform: translateX(55px);
  }
  @media all and (max-width: 1200px) {
    transform: translateX(60px);
  }
`
const BGImg = styled.img`
  width: 100%;
  display: block;
`
