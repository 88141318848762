import React from 'react'
import styled from 'styled-components'

export default function Missions() {
  return (
    <Container id="mission">
      <Cover className="wrapper">
        <p className="blue">Our Mission</p>
        <Head>Mission Statement</Head>
        <Description>
          Our mission is to be a sustainable construction contractor by adhering
          and deploying green building practices and materials over the entire
          life-cycle of our projects, from concept to completion. Our passion is
          to staying current with the newest innovations of our industry, with
          due consideration of environment.
        </Description>

        <ImageContainer>
          <img
            src="https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex%2Fmission-spot.png"
            alt="mission spot"
          />
        </ImageContainer>
      </Cover>
    </Container>
  )
}
const Container = styled.section`
  padding: 100px 0;
  background: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  @media all and (max-width: 1280px) {
    padding: 80px 0;
  }
  @media all and (max-width: 980px) {
    padding: 70px 0;
  }
  @media all and (max-width: 640px) {
    padding: 60px 0;
  }
  @media all and (max-width: 480px) {
    padding: 50px 0;
  }
`
const Cover = styled.section`
  &.wrapper {
    width: 75%;
    margin: 0 auto;
    @media (max-width: 1200px) {
      width: 80%;
    }
    @media all and (max-width: 980px) {
      flex-wrap: wrap;
    }
    @media all and (max-width: 640px) {
      width: 85%;
    }
    @media (max-width: 480px) {
      width: 90%;
    }
  }
  p.blue {
    background-image: linear-gradient(45deg, #20a0f3, #336687);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    font-family: 'gordita_medium';
    text-align: center;
    margin-bottom: 15px;
  }
`
const Head = styled.h3`
  color: #212121;
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
  font-family: 'gordita_bold';

  @media all and (max-width: 1080px) {
    font-size: 24px;
  }
  @media all and (max-width: 980px) {
    text-align: center;
  }

  b {
    color: #0bbe61;
    font-family: gordita_bold;
  }
`
const Description = styled.p`
  font-size: 16px;
  text-align: center;
  width: 70%;
  margin: 0 auto 80px auto;
  color: #747474;
  @media all and (max-width: 1080px) {
    font-size: 14px;
    width: 100%;
  }
  @media all and (max-width: 768px) {
    margin: 0 auto 50px auto;
  }
`

const ImageContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  img {
    display: block;
    width: 100%;
  }
  @media all and (max-width: 980px) {
    width: 90%;
    margin: 0 auto;
  }
  @media all and (max-width: 768px) {
    width: 100%;
    margin: 0 auto;
  }
`
